import {navigate} from 'gatsby';
import React, {FC, useEffect, useState} from 'react';
import Layout from '../../components/layout';

export interface UserVerifyComponentProps {
  token: string;
}

const VerifyEmail: FC<UserVerifyComponentProps> = ({token}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    token && token !== 'index.html' && verify();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => success && navigate('/'), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const verify = async () => {
    setSuccess(false);
    setErrorMessage('');
    try {
      const result = await fetch(`https://api.hexometer.com/v2/ql`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation {
            WatchSubscribeOps {
              verify (verifyCode: "${token}") {
                error
                message
              }
            }
          }`,
        }),
      });
      await result
        .json()
        .then(({data}) => {
          if (data && data.WatchSubscribeOps && data.WatchSubscribeOps.verify) {
            if (data.WatchSubscribeOps.verify.error) {
              setErrorMessage(data.WatchSubscribeOps.verify.message.replaceAll('_', ' '));
            } else {
              setSuccess(true);
            }
          }
        })
        .catch(() => {});
    } catch (e) {}
  };

  return (
    <Layout className="verify-code-page">
      <div className="verify-code-section">
        <p className="h5 mb-5 mt-1 text-center f_700 f_size_40 title">Email verification</p>
        <div className="col-lg-12 text-center" style={{height: '190px'}}>
          {errorMessage ? (
            <div className="alert alert-danger d-inline-block px-5">{errorMessage}</div>
          ) : success ? (
            <>
              <p>You will be automatically redirected to home page in 5 seconds</p>
              <div className="alert alert-success d-inline-block px-5">
                {success && <>Email has been successfully verified</>}
              </div>
            </>
          ) : (
            <div className="spinner-border hexact-color" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default VerifyEmail;
